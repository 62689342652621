<template>
  <div ref="recaptchaContainer"></div>
</template>

<script>
function loadRecaptcha(callback) {
  const win = window
  if (win && !win.grecaptcha) {
    const recaptchaScript = document.createElement('script')
    document.head.appendChild(recaptchaScript)
    recaptchaScript.onload = () => {
      const win = window
      win.grecaptcha.ready(() => {
        callback()
      })
    }
    recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js')
  } else {
    callback()
  }
}

export default {
  name: 'Recaptcha',
  data() {
    return {
      passed: false,
      widgetId: undefined
    }
  },
  computed: {
    siteKey() {
      return process.env.VUE_APP_RECAPTCHA_SITE_KEY || 'site-key-not-defined'
    }
  },
  methods: {
    mountRecaptcha() {
      const recaptchaApi = (window).grecaptcha
      const container = this.$refs.recaptchaContainer
      this.widgetId = recaptchaApi.render(container, {
        callback: () => {
          const result = recaptchaApi.getResponse(this.widgetId)
          this.passed = true
          this.$emit('change', this.passed)
          this.$emit('passed', result)
        },
        'expired-callback': () => {
          this.passed = false
          this.$emit('change', this.passed)
          this.$emit('expired')
        },
        sitekey: this.siteKey
      })
    }
  },
  mounted() {
    loadRecaptcha(() => this.mountRecaptcha())
  }
}
</script>
