<template>
  <div class="flex pb-24 mt-24 min-h-screen">
    <div class="px-6 mx-auto w-full max-w-md">
      <h1>Register</h1>
      <form @submit.prevent="register">
        <div class="form-field">
          <label for="email">Email</label>
          <input name="email" type="email" v-model="user.email" required>
        </div>
        <div class="form-field">
          <label for="password">Password</label>
          <input name="password" type="password" v-model="user.password" required>
        </div>
        <div class="form-field">
          <label for="password">Confirm Password</label>
          <input name="password" type="password" v-model="repassword" required>
        </div>
        <div class="flex justify-center">
          <recaptcha @change="onCaptchaChange" @passed="onCaptchaPassed"></recaptcha>
        </div>
        <button class="block py-3 px-0 mt-4 w-full leading-5 btn" type="submit">
          {{ !loading ? 'Create Account' : 'Loading...' }}
        </button>
      </form>
      <p class="mt-8 text-center">
        <a class="underline" @click.prevent="$router.push('/resend-verification')">Resend Verification</a>
      </p>
      <p class="mt-4 text-center">
        <router-link class="underline" to="/login">Or login here</router-link>
      </p>
      <div class="mt-8 h-32">
        <p v-if="info">{{ info }}</p>
        <p v-if="error" class="text-red-600">{{ error }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Recaptcha from '@/components/Recaptcha.vue'
import { capitalize } from '../../utils'

export default {
  components: { Recaptcha },
  data() {
    return {
      user: {
        email: '',
        password: ''
      },
      repassword: '',
      error: '',
      info: '',
      verify: false
    }
  },
  computed: {
    ...mapState('users', { loading: 'isCreatePending' })
  },
  mounted() {
    this.$ga.page('/register')
  },
  methods: {
    ...mapActions('auth', ['authenticate']),
    register() {
      this.info = ''
      this.error = ''
      if (!(this.user.email && this.user.password && this.repassword)) {
        this.error = 'All field is required.'
      } else if (this.user.password !== this.repassword) {
        this.error = 'Confirm password does not match.'
      } else if (this.verify) {
        const { User } = this.$FeathersVuex.api
        const user = new User(this.user)
        user.save().then(() => {
          this.info = 'Registration success. Check your email to activate account.'
          window.grecaptcha.reset()
        }).catch(error => {
          this.error = capitalize(error.message)
        })
      }
    },
    onCaptchaChange() {
      this.verify = false
    },
    onCaptchaPassed() {
      this.verify = true
    }
  }
}
</script>
